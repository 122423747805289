<template>
  <div id="skills_form">
    <div class="back_button_section">
      <back-button />
    </div>
    <div class="block" v-for="(item, index) in skills" :key="index">
      <single-select @onClick="onSelect(item)" />
      <div class="block_heading">{{ item }}</div>
    </div>
    <div class="button_section">
      <apply-button :active="activateNext" text="NEXT" @onClick="onClickNext" />
    </div>
  </div>
</template>

<script>
import SingleSelectStripped from "@/components/SingleSelectStripped";
import ApplyButton from "@/components/ApplyButton";
import difference from "lodash/difference";
import BackButton from "@/components/BackButton";
export default {
  name: "SkillsForm",
  components: {
    "single-select": SingleSelectStripped,
    "apply-button": ApplyButton,
    "back-button": BackButton
  },
  data() {
    return {
      skills: this.$store.getters.getOpening(parseInt(this.$route.params.id))
        .required_skills,
      mandatory_skills: this.$store.getters.getOpening(
        parseInt(this.$route.params.id)
      ).mandatory_skills,
      selected_skills: []
    };
  },
  computed: {
    activateNext() {
      // if the mandatory skills are selected activate
      if (
        difference(this.mandatory_skills, this.selected_skills).length === 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    onSelect(selected) {
      // check if the selected already exists
      if (this.selected_skills.includes(selected)) {
        // remove
        let index = this.selected_skills.indexOf(selected);
        if (index > -1) {
          this.selected_skills.splice(index, 1);
        }
      } else {
        this.selected_skills.push(selected);
      }
    },
    onClickNext() {
      // commit the selected skills to store
      this.$store
        .dispatch("setApplicantData", {
          skills: this.selected_skills
        })
        .then(() => {
          this.$router.push("/apply/" + this.$route.params.id + "/verify");
        });
    }
  }
};
</script>

<style scoped>
#skills_form {
  @apply flex flex-col w-full px-0 py-12;
  @apply md:px-8 md:py-16;
  @apply lg:px-12 lg:py-20;
}
.block {
  @apply flex flex-row font-titillium items-center justify-start my-3.5;
  @apply lg:text-base;
}
.block_heading {
  @apply ml-5 md:ml-6 lg:ml-8 text-xl;
}
.button_section {
  @apply mt-10;
  @apply lg:mt-14;
}
.back_button_section {
  @apply mb-8;
  @apply md:mb-10;
  @apply lg:mb-12;
}
</style>
