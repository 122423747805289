// all the api calls go here
const API = require("./API");

module.exports.saveForm = data => {
  // return promise
  return new Promise((resolve, reject) => {
    // post data to the backend
    fetch(API.default + "/developer/save", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.success === true) {
          resolve();
        } else if (
          data.success === false &&
          data.msg === "mobile number already exists"
        ) {
          // set error
          reject();
        } else {
          reject();
        }
      })
      .catch(err => {
        console.log(err);
      });
  });
};

// generate both the otp
module.exports.generateOtp = (mobile, email) => {
  // return new promise
  return new Promise((resolve, reject) => {
    // set promise array
    let promise_array = new Array();
    // send email otp
    promise_array.push(
      new Promise((resolve, reject) => {
        // set data
        let data = {
          email,
          origin: "kollcorp.com"
        };
        fetch(API.default + "/otp/email/generate", {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json"
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
          .then(response => response.json())
          .then(data => {
            if (data.success === true) {
              resolve();
            }
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      })
    );

    // send mobile otp
    promise_array.push(
      new Promise((resolve, reject) => {
        // set data
        let data = {
          mobile
        };
        fetch(API.default + "/otp/generate", {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json"
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
          .then(response => response.json())
          .then(data => {
            if (data.success === true) {
              resolve();
            }
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      })
    );

    // resolve all promise
    Promise.all(promise_array)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};

// generate email otp
module.exports.generateEmailOtp = email => {
  return new Promise((resolve, reject) => {
    let data = {
      email,
      origin: "kollcorp.com"
    };
    fetch(API.default + "/otp/email/generate", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === true) {
          resolve();
        }
      })
      .catch(err => {
        console.log(err);
        reject();
      });
  });
};

// generate mobile otp
module.exports.generateMobileOtp = mobile => {
  return new Promise((resolve, reject) => {
    // set data
    let data = {
      mobile
    };
    fetch(API.default + "/otp/generate", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === true) {
          resolve();
        }
      })
      .catch(err => {
        console.log(err);
        reject();
      });
  });
};

// validate mobile otp
module.exports.validateMobileOtp = (mobile, otp) => {
  // return promise
  return new Promise((resolve, reject) => {
    var data = {
      mobile,
      otp
    };
    fetch(API.default + "/otp/validate", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === true) {
          resolve();
        } else {
          reject();
        }
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
};

// validate email otp
module.exports.validateEmailOtp = (email, otp) => {
  // return new promise
  return new Promise((resolve, reject) => {
    var data = {
      email,
      otp
    };
    fetch(API.default + "/otp/email/validate", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === true) {
          resolve();
        } else {
          reject();
        }
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
};

// verify developer
module.exports.verifyDeveloper = mobile => {
  return new Promise((resolve, reject) => {
    fetch(API.default + "/developer/verify", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ mobile }) // body data type must match "Content-Type" header
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === true) {
          resolve();
        } else {
          reject();
        }
      })
      .catch(err => {
        console.log(err);
        reject();
      });
  });
};

// get all the openings
module.exports.fetchOpenings = () => {
  return new Promise((resolve, reject) => {
    fetch(API.default + "/opening/get_all", {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
      .then(response => response.json())
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        console.log(err);
        reject();
      });
  });
};

// save applicant
module.exports.saveApplicant = data => {
  return new Promise((resolve, reject) => {
    fetch(API.default + "/opening/applicant/save", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === true) {
          resolve();
        } else {
          reject();
        }
      })
      .catch(err => {
        console.log(err);
        reject();
      });
  });
};
