<template>
  <div id="privacy_policy">
    <div class="header fixed z-50 w-screen" ref="base_header">
      <Header />
    </div>

    <div
      class="privacy_policy_content"
      :style="{
        'padding-top': contentPadding + 'px',
        'padding-bottom': contentPadding + 'px'
      }"
    >
      <div class="main_heading">
        Privacy Policy
      </div>

      <div class="content_wrapper">
        <div class="content_block">
          <div class="text">
            At Koll Corporate Services Private Limited, accessible from the App,
            one of our main priorities is the privacy of our visitors. This
            Privacy Policy document contains the types of information that is
            collected and recorded by Koll Corporate Services Private Limited,
            and how we use it.
            <br /><br />
            If you have additional questions or require more information about
            our Privacy Policy, contact us at hello@kollcorp.com.
            <br /><br />
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our App with regards to the information that
            they share with and / or collect from Koll Corporate Services
            Private Limited. This policy is not applicable to any information
            collected offline or via channels other than this App.
          </div>
        </div>
        <div class="content_block">
          <div class="sub_heading">
            DEFINITION
          </div>
          <div class="text">
            APP: The term ‘App’ includes the website, static content, dynamic
            content, and mobile applications in the Terms of Use.
          </div>
        </div>
        <div class="content_block">
          <div class="sub_heading">
            CONSENT
          </div>
          <div class="text">
            By using our App, you hereby consent to our Privacy Policy and agree
            to its terms.
          </div>
        </div>
        <div class="content_block">
          <div class="sub_heading">INFORMATION WE COLLECT</div>
          <div class="text">
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
            <br /><br />
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and / or attachments you may send us, and
            any other information you may choose to provide.
            <br /><br />
            When you register for an Account, we may ask for your contact
            information, including information such as your name, company name,
            address, and email address, date of birth, and contact number.
          </div>
        </div>
        <div class="content_block">
          <div class="sub_heading">INFORMATION AUTOMATICALLY COLLECTED</div>
          <div class="text">
            You can visit our App without telling us who you are or revealing
            any information about yourself. We, and our third party service
            providers or other partners (collectively “Partners”) may use
            automated means to collect various types of information about you,
            your computer or other device used to access our App. A
            representative, non-exhaustive list of the types of automatically
            collected information may include: network or Internet protocol
            address and type of browser you are using (e.g., Chrome, Safari,
            Firefox, Internet Explorer), the type of operating system you are
            using, (e.g., Microsoft Windows or Mac OS), mobile network, device
            identifiers, device settings, browser settings, the pages you have
            visited within the App, Apps visited before and after you visited
            our App, the type of handheld or mobile device used to view the App
            (e.g., iOS, Android), location information, and the content and
            advertisements you have accessed, seen, forwarded and / or clicked
            on. Please see our Section titled Cookies, for more information
            about how the Information may be collected and used.
          </div>
        </div>
        <div class="content_block">
          <div class="sub_heading">HOW WE USE YOUR INFORMATION</div>
          <div class="text">
            We use the information we collect in various ways, including to:
            <ul class="bullet_points">
              <li>Provide, operate, and maintain our App</li>
              <li>Improve, personalize, and expand our App</li>
              <li>Understand and analyze how you use our App</li>
              <li>
                Develop new products, services, features, and functionality
              </li>
              <li>To safeguard our customers, clients, users and visitors.</li>
              <li>
                Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the App, and for
                marketing and promotional purposes
              </li>
              <li>Send you emails</li>
              <li>Find and prevent fraud and illegal activities</li>
              <li>
                To enable and comply with requirements imposed by laws
                applicable to us.
              </li>
            </ul>
          </div>
        </div>
        <div class="content_block">
          <div class="sub_heading">LOG FILES</div>
          <div class="text">
            Koll Corporate Services Private Limited follows a standard procedure
            of using log files. These files log visitors when they visit the
            App. All hosting companies do this as part of the hosting services’
            analytics. The information collected by the log files include
            internet protocol (IP) addresses, browser type, Internet Service
            Provider (ISP), date and time stamp, referring / exit pages, and
            possibly the number of clicks. These are not linked to any
            information that is personally identifiable. The purpose of the
            information is for analyzing trends, administering the site,
            tracking users’ movement on the App, and gathering demographic
            information.
          </div>
        </div>
        <div class="content_block">
          <div class="sub_heading">
            COOKIES AND WEB BEACONS
          </div>
          <div class="text">
            Like any other App, Koll Corporate Services Private Limited uses
            ‘cookies’. These cookies are used to store information, including
            visitors’ preferences, and the pages on the App that the visitor
            accessed or visited. The information is used to optimize the user’s
            experience by customizing our web page content based on the
            visitor’s browser type and / or other information.
          </div>
        </div>
        <div class="content_block">
          <div class="sub_heading">ADVERTISING PARTNERS PRIVACY POLICIES</div>
          <div class="text">
            We currently do not display third-party advertisements on our App.
            In the future, if we choose to publish any third-party ad, the
            following terms shall apply: <br /><br />
            Third-party ad servers or ad networks use technologies like cookies,
            JavaScript, or Web Beacons that are used in their respective
            advertisements and links that appear on Koll Corporate Services
            Private Limited, which are sent directly to users’ browsers. They
            automatically receive your IP address when this occurs. These
            technologies are used to measure the effectiveness of their
            advertising campaigns and / or to personalize the advertising
            content that you see on the Apps that you visit.
            <br /><br />
            Note that Koll Corporate Services Private Limited has no access to
            or control over these cookies that are used by third-party
            advertisers.
          </div>
        </div>
        <div class="content_block">
          <div class="sub_heading">SECURITY AND DATA STORING</div>
          <div class="text">
            We are taking security measures to protect the confidentiality,
            information, and data of our users and visitors. We are maintaining
            and taking strict measures through electronic mode and
            administration to protect the privacy of our users and visitors.
            While we are taking all the necessary measures to protect the safety
            of our users and visitors, we cannot guarantee that someone cannot
            overcome our security measure. Therefore, the information provided
            by you on this App also constitutes your acceptance of the risk that
            may be caused by submitting the information. This acceptance also
            waives off the right to seek legal remedies from us regarding any
            misuse of the information provided by you on this App.
          </div>
        </div>
        <div class="content_block">
          <div class="sub_heading">THIRD PARTY PRIVACY POLICIES</div>
          <div class="text">
            Koll Corporate Services Private Limited’s Privacy Policy does not
            apply to other advertisers or Apps. Thus, we are advising you to
            consult the respective Privacy Policies of these third-party ad
            servers for more detailed information. It may include their
            practices and instructions about how to opt-out of certain options.
            <br /><br />
            You can choose to disable cookies through your individual browser
            options. More detailed information about cookie management with
            specific web browsers can be found on the browsers’ respective Apps.
          </div>
        </div>
        <div class="content_block">
          <div class="sub_heading">GDPR DATA PROTECTION RIGHTS</div>
          <div class="text">
            We would like to make sure you are fully aware of all your data
            protection rights. Every user is entitled to the following:
            <ul class="bullet_points">
              <li>
                The right to access: You have the right to request copies of
                your personal data. We may charge you a small fee for this
                service.
              </li>
              <li>
                The right to rectification: You have the right to request that
                we correct any information you believe is inaccurate. You also
                have the right to request that we complete the information you
                believe is incomplete.
              </li>
              <li>
                The right to erasure: You have the right to request that we
                erase your personal data, under certain conditions.
              </li>
              <li>
                The right to restrict processing: You have the right to request
                that we restrict the processing of your personal data.
              </li>
              <li>
                The right to object to processing: You have the right to object
                to our processing of your personal data, under certain
                conditions.
              </li>
              <li>
                The Right to Confidentiality and Security: We make sure that
                your data and information is secure and the necessary preventive
                measures are taken care of to ensure no data loss and theft.
                However, we cannot make any guarantees and you accept the risk
                associated with that.
              </li>
              <li>
                The Right to Limitation of Storage: The information of users and
                visitors of this App shall remain with us for at least 180 (One
                hundred and eighty) days after your non-availability on the App
                to ensure compliance with legal regulations. However, we make
                sure to delete the data and information of the user and visitors
                when it is no longer required for any purpose.
              </li>
              <li>
                The right to data portability: You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </li>
            </ul>
            <br /><br />
            If you make a request, we have 1 (One) month in which to respond. If
            you would like to exercise any of these rights, please contact us at
            hello@kollcorp.com.
          </div>
        </div>
        <div class="content_block">
          <div class="sub_heading">CHILDREN’S INFORMATION</div>
          <div class="text">
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
            <br /><br />
            Koll Corporate Services Private Limited does not knowingly collect
            any Personal Identifiable Information from children under the age of
            15 (Fifteen) years. If you think that your child provided this kind
            of information on our App, we strongly encourage you to contact us
            immediately and we will do our best to promptly remove such
            information from our records.
          </div>
        </div>
        <div class="content_block">
          <div class="sub_heading">UPDATES</div>
          <div class="text">
            We may change this Privacy Policy from time to time as per the
            requirement and governing laws of India and you should check for
            updates regularly. Your use of the App will be deemed an acceptance
            of the privacy policy existing at that time.
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "PrivacyPolicy",
  components: {
    Header,
    Footer
  },
  computed: {
    contentPadding() {
      if (!this.isMounted) {
        return;
      } else {
        return 2 * this.$refs.base_header.clientHeight;
      }
    }
  },
  data() {
    return {
      isMounted: false
    };
  },
  mounted() {
    // make mounted true
    this.isMounted = true;
    // scroll back to top when mounted
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
};
</script>

<style scoped>
#privacy_policy {
  @apply flex flex-col min-h-screen;
}
.privacy_policy_content {
  @apply h-auto w-full flex flex-col items-center px-5;
  @apply sm:px-10;
  @apply lg:px-20;
  @apply 2xl:px-60;
}
.main_heading {
  @apply font-titillium text-4xl;
  @apply md:text-6xl;
  @apply lg:text-7xl;
}
.content_wrapper {
  @apply w-full bg-customLightBlue-extralight rounded-sm mt-10 px-5;
  @apply sm:px-10 sm:py-5;
  @apply md:mt-16;
  @apply lg:px-20 lg:py-14 lg:mt-20;
}
.content_block {
  @apply py-5;
  @apply md:py-7;
}
.text {
  @apply font-titillium;
  @apply md:text-lg;
}
.text_bold {
  @apply font-semibold;
}
.sub_heading {
  @apply font-titillium font-semibold mb-3;
  @apply md:text-lg;
}
.bullet_points {
  list-style-type: disc;
  list-style-position: outside;
}
.bullet_points li {
  @apply my-3 ml-5;
}
</style>
