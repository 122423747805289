// set api link when production and development
var API = "";

if (process.env.NODE_ENV === "development") {
  API = "https://api.strongunited.org";
} else if (process.env.NODE_ENV === "production") {
  API = "https://api.strongunited.org";
} else if (process.env.NODE_ENV === "local") {
  API = "http://localhost:3000";
}

export default API;
