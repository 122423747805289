<template>
  <div id="verify_form">
    <div class="content">
      <div class="back_button_section">
        <back-button />
      </div>
      <div class="verify_section">
        <div class="otp_section">
          <div class="otp_block margin">
            <div class="heading_section">
              <div class="otp_heading">Mobile OTP</div>
            </div>
            <div class="input_block">
              <input
                type="text"
                class="inputbox mob"
                id="mob_input"
                placeholder="Mobile"
                :value="mob"
                @input="onInputMob"
                :class="{ error_box: errorMob }"
                :disabled="disableMob"
              />
              <div class="plus">+91</div>
            </div>
            <div class="link_section">
              <button
                class="link"
                @click="onClickEditMob"
                v-if="disableMob"
                key="mob"
              >
                Edit
              </button>
              <button
                class="link"
                @click="onClickSaveMob"
                v-if="!disableMob"
                key="mob"
              >
                Save
              </button>
            </div>

            <div class="otp_input">
              <otp-input
                :error="errorMobOtp"
                @change="mobOtpChange"
                @completed="mobOtpComplete"
                :verified="mobileOtpVerified"
              />
            </div>
          </div>
          <div class="otp_block email_otp_section">
            <div class="heading_section">
              <div class="otp_heading">Email OTP</div>
            </div>
            <div class="input_block">
              <input
                type="text"
                class="inputbox"
                id="email_input"
                placeholder="Email"
                v-model="email"
                :class="{ error_box: errorEmail }"
                :disabled="disableEmail"
              />
            </div>
            <div class="link_section">
              <button
                class="link"
                @click="onClickEditEmail"
                v-if="disableEmail"
                key="email"
              >
                Edit
              </button>
              <button
                class="link"
                @click="onClickSaveEmail"
                v-if="!disableEmail"
                key="email"
              >
                Save
              </button>
            </div>
            <div class="otp_input">
              <otp-input
                :error="errorEmailOtp"
                @change="emailOtpChange"
                @completed="emailOtpComplete"
                :verified="emailOtpVerified"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="button_section verify_button">
        <apply-button
          :active="activateVerify"
          text="Verify"
          @onClick="onClickVerify"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApplyButton from "@/components/ApplyButton";
import OTPInput from "@/components/OTPInput";
import BackButton from "@/components/BackButton";
import {
  generateEmailOtp,
  generateMobileOtp,
  validateMobileOtp,
  validateEmailOtp,
  saveApplicant
} from "@/services";
export default {
  name: "VerifyForm",
  components: {
    "apply-button": ApplyButton,
    "otp-input": OTPInput,
    "back-button": BackButton
  },
  data() {
    return {
      mob: this.$store.getters.getApplicant.mobile,
      email: this.$store.getters.getApplicant.email,
      errorMob: false,
      errorEmail: false,
      disableMob: true,
      disableEmail: true,
      mobileOtpVerified: false,
      emailOtpVerified: false,
      errorMobOtp: false,
      errorEmailOtp: false
    };
  },
  computed: {
    activateVerify() {
      // if both the otp verified activate
      if (this.mobileOtpVerified && this.emailOtpVerified) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    // generate email otp
    generateEmailOtp(this.email).then(() => {
      console.log("email otp generated");
    });
    // generate mobile otp
    generateMobileOtp(this.mob).then(() => {
      console.log("mobile otp generated");
    });
  },
  methods: {
    onClickVerify() {
      saveApplicant(this.$store.getters.getApplicant)
        .then(() => {
          console.log("applicant saved to server");
          this.$router.replace("/apply/" + this.$route.params.id + "/complete");
        })
        .catch(err => {
          console.log(err);
        });
    },
    onInputMob: function(e) {
      //stop the input when input length reaches 10
      if (e.target.value.replace(/\D/g, "").length <= 10) {
        // strip characters
        var strippedMob = e.target.value.replace(/\D/g, "");
        // if the first value is 0, delete it
        if (strippedMob[0] === "0") {
          // slice the 0
          strippedMob = strippedMob.slice(1);
        }
        this.mob = strippedMob;
      }
      // the above equates to an empty string if the input is only characters, vue's reactivity will not update, so force update on every input
      this.$forceUpdate();
    },
    validateMob: function() {
      // if mobile is empty return false
      if (this.mob == "" || this.mob.length < 10) {
        this.errorMob = true;
        return false;
      } else if (this.mob.length === 10) {
        this.errorMob = false;
        return true;
      }
    },
    validateEmail: function() {
      // email format in regex code
      var validEmailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (this.email === "") {
        this.errorEmail = true;
        return false;
      } else if (!validEmailFormat.test(this.email)) {
        this.errorEmail = true;
        return false;
      } else {
        this.errorEmail = false;
        return true;
      }
    },
    onClickEditMob: function() {
      // enable mobile input
      this.disableMob = false;
    },
    onClickEditEmail: function() {
      // enable email input
      this.disableEmail = false;
    },
    onClickSaveMob: function() {
      if (this.errorMob === false) {
        // disable mobile input
        this.disableMob = true;
        // save mobile at store
        this.$store.dispatch("setApplicantData", {
          mobile: this.mob
        });

        generateMobileOtp(this.mob).then(() => {
          console.log("mobile otp generated");
        });
      }
    },
    onClickSaveEmail: function() {
      if (this.errorEmail === false) {
        // disable email input
        this.disableEmail = true;
        // save email at store
        this.$store.dispatch("setApplicantData", {
          email: this.email
        });

        generateEmailOtp(this.email).then(() => {
          console.log("email otp generated");
        });
      }
    },
    emailOtpChange: function() {
      this.emailEmailOtp = false;
    },
    mobOtpChange: function() {
      this.errorMobOtp = false;
    },
    emailOtpComplete: function(value) {
      // verify
      validateEmailOtp(this.email, value)
        .then(() => {
          this.emailOtpVerified = true;
        })
        .catch(() => {
          this.errorEmailOtp = true;
        });
    },
    mobOtpComplete: function(value) {
      validateMobileOtp(parseInt(this.mob), value)
        .then(() => {
          this.mobileOtpVerified = true;
        })
        .catch(() => {
          this.errorMobOtp = true;
        });
    }
  },
  watch: {
    mob() {
      this.validateMob();
    },
    email() {
      this.validateEmail();
    }
  }
};
</script>

<style scoped>
#verify_form {
  @apply flex flex-col w-full px-0 py-12;
  @apply md:px-8 md:py-16;
  @apply lg:px-12 lg:py-20;
}
.otp_section {
  @apply flex flex-col w-full;
  @apply sm:flex-row;
}
.otp_block {
  @apply w-full max-w-xs flex flex-col items-center;
}
.otp_input {
  @apply mt-2;
  @apply md:mt-2;
  @apply lg:mt-3;
}
.otp_heading {
  @apply font-titillium font-light text-xl;
  @apply sm:text-base;
  @apply md:text-lg;
  @apply lg:text-xl;
}
.inputbox {
  @apply focus:outline-none rounded-lg border border-customGray-light w-full px-4 py-2.5 font-titillium text-base placeholder-customGray-light font-normal focus:placeholder-opacity-0;
  @apply sm:px-3 sm:py-2.5 sm:text-sm;
  @apply md:px-5 md:py-3 md:text-sm;
  @apply lg:text-base;
}
.inputbox::placeholder {
  @apply font-extralight;
}
.error_box {
  @apply border-red;
}
.input_block {
  @apply mt-5 relative overflow-hidden max-w-xs w-full;
  @apply md:mt-5;
  @apply lg:mt-8;
}
.plus {
  @apply absolute top-0 font-titillium text-sm px-2 border-r border-customGray-light py-3.5 font-light text-red;
  @apply md:text-sm;
  @apply lg:text-base;
}
.mob {
  @apply pl-14 !important;
}
.margin {
  @apply sm:mr-8 md:mr-16 lg:mr-28;
}
.link_section {
  @apply w-full;
}
.link {
  @apply font-titillium text-customLightBlue-dark underline text-base active:opacity-50 mt-2;
  @apply sm:text-xs;
  @apply md:text-sm md:underline;
  @apply lg:text-base;
}
.heading_section {
  @apply w-full;
}
.timer_resend_section {
  @apply w-full;
}
.button_section {
  @apply mt-10;
}
.email_otp_section {
  @apply mt-14;
  @apply sm:mt-0;
}
.back_button_section {
  @apply mb-8;
  @apply md:mb-10;
  @apply lg:mb-12;
}
</style>
