var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contaniner"},[_c('div',{staticClass:"header fixed z-50 w-screen"},[_c('Header')],1),_c('div',{attrs:{"id":"apply"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"sub_heading"},[_vm._v(" APPLY DIRECTLY ")]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.opening.title)+" ")]),_c('div',{staticClass:"location"},[_vm._v(" "+_vm._s(_vm.opening.location)+" ")]),_c('div',{staticClass:"flow_section",class:{ invisible: _vm.page === 'complete' }},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"step",class:{
              step_active: _vm.page === 'profile',
              step_finished: _vm.page !== 'profile'
            }},[_vm._v(" STEP 1 ")]),_c('div',{staticClass:"section_heading",class:{
              step_active: _vm.page === 'profile',
              step_finished: _vm.page !== 'profile'
            }},[_vm._v(" Profile ")])]),_c('div',{staticClass:"section"},[_c('div',{staticClass:"step",class:{
              step_active: _vm.page === 'skills',
              step_finished: _vm.page !== 'skills' && _vm.page !== 'profile'
            }},[_vm._v(" STEP 2 ")]),_c('div',{staticClass:"section_heading",class:{
              step_active: _vm.page === 'skills',
              step_finished: _vm.page !== 'skills' && _vm.page !== 'profile'
            }},[_vm._v(" Skills ")])]),_c('div',{staticClass:"section"},[_c('div',{staticClass:"step",class:{
              step_active: _vm.page === 'verify',
              step_finished: _vm.page === 'complete'
            }},[_vm._v(" STEP 3 ")]),_c('div',{staticClass:"section_heading",class:{
              step_active: _vm.page === 'verify',
              step_finished: _vm.page === 'complete'
            }},[_vm._v(" Verify ")])])])]),_c('div',{staticClass:"form_section"},[(_vm.keptAlive)?_c('keep-alive',{attrs:{"include":_vm.cacheEnabledComponentsList}},[_c('router-view')],1):_c('router-view')],1)]),_c('div',{staticClass:"footer"},[_c('Footer')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }