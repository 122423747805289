<template>
  <div id="checkbox_wrapper">
    <label>
      <input
        v-bind="$attrs"
        :checked="checked"
        v-on="inputListeners"
        id="custom_checkbox"
      />
      <span id="check_border"></span>
      <span id="check"></span>
    </label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    prop: "checked",
    event: "input"
  },
  props: {
    checked: {
      type: Boolean
    }
  },
  computed: {
    inputListeners: function() {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function(event) {
            vm.$emit("input", event.target.checked);
          }
        }
      );
    }
  }
};
</script>

<style scoped>
#custom_checkbox {
  display: none;
}
#check {
  @apply block w-5 h-5 border-2 border-white border-solid;
  border-radius: 5px;
}
#custom_checkbox:checked ~ #check {
  @apply bg-customLightBlue-dark;
}
#custom_checkbox:checked ~ #check_border {
  @apply border-customLightBlue-dark;
}
#checkbox_wrapper {
  @apply relative;
  width: 1.35rem;
  height: 1.35rem;
}
#check_border {
  @apply border border-gray-400 absolute;
  top: -1px;
  left: -1px;
  width: 1.35rem;
  height: 1.35rem;
  border-radius: 5px;
}
</style>
