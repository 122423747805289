<template>
  <div class="main_section" :style="mainSectionStyle">
    <div class="main_content">
      Innovating with technology. <br /><span class="mt-0 block"
        >Executing with vigor.</span
      >
    </div>
    <div class="sub_content">
      We are hiring
      <span class="inline bg-red text-white px-3 sm:px-3 sm:py-1 sm:pr-0"
        >startup-oriented
      </span>
      <br class="sm:hidden" />
      <span class="inline bg-red text-white px-3 sm:px-3 sm:py-1 sm:pl-0"
        >software engineers</span
      >
      in India.
      <span class="font-semibold underline"
        ><a href="#current_positions_section">Apply below.</a></span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "MainSection",
  data() {
    return { windowHeight: window.innerHeight };
  },
  computed: {
    mainSectionStyle() {
      return { height: `${this.windowHeight}px`, width: `100%` };
    }
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
    }
  },
  mounted() {
    // on window resize
    this.$nextTick(() => {
      // remove resize
      //window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    //window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style scoped>
.main_section {
  @apply w-full flex flex-col justify-center px-5 py-20 font-titillium;
  @apply sm:px-5;
  @apply md:px-10;
  @apply lg:px-20;
}
.main_content {
  @apply text-5xl text-customLightBlue-dark font-light mt-20 leading-tight;
  @apply sm:text-5xl;
  @apply md:text-6xl md:mt-24;
  @apply lg:text-7xl lg:mt-28;
}
.sub_content {
  @apply text-red text-xl mt-10 mb-14 leading-loose;
  @apply sm:leading-normal;
  @apply md:mt-20;
  @apply lg:text-2xl;
}
</style>
