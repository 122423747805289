<template>
  <div id="otp_input" :class="{ errorStyle: error, verifiedStyle: verified }">
    <v-otp-input
      ref="otpInput"
      :input-classes="otpClass"
      separator="<span class='w-2'></span>"
      :num-inputs="4"
      :should-auto-focus="true"
      :is-input-num="true"
      @on-complete="handleOnComplete"
      @on-change="handleOnChange"
    />
  </div>
</template>

<script>
const OtpInput = () =>
  import(/* webpackChunkName: "Utils" */ "@bachdgvn/vue-otp-input");

export default {
  name: "OTPInput",
  components: {
    "v-otp-input": OtpInput
  },
  props: {
    error: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    otpClass() {
      if (this.error) {
        return "otp-input-error";
      } else if (this.verified) {
        return "otp-input-verified";
      } else {
        return "otp-input";
      }
    }
  },
  methods: {
    handleOnComplete: function(value) {
      this.$emit("completed", value);
    },
    handleOnChange: function(value) {
      this.$emit("change", value);
    }
  }
};
</script>

<style>
#otp_input {
  @apply inline-block h-auto;
}
.otp-input {
  @apply font-titillium text-xl w-14 h-14 mt-3 py-1 mx-4 text-center rounded-md border border-customGray-light;
}
.otp-input-error {
  @apply font-titillium text-xl w-14 h-14 mt-3 py-1 mx-4 text-center rounded-md border border-red !important;
}
.otp-input-verified {
  @apply font-titillium text-xl w-14 h-14 mt-3 py-1 mx-4 text-center rounded-md border border-customLightBlue-dark !important;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.error {
  @apply border border-red !important;
}
.otp-input:focus {
  @apply outline-none;
}
.otp-input-error:focus {
  @apply outline-none;
}
.otp-input-verified:focus {
  @apply outline-none;
}
.errorStyle {
  @apply border-red !important;
}
.verifiedStyle {
  @apply border-customLightBlue-dark !important;
}
</style>
