<template>
  <div class="home">
    <div class="header fixed z-50 w-screen">
      <Header />
    </div>
    <div id="content">
      <main-section />
      <about-us-section />
      <work-with-us-section />
      <current-positions-section />
    </div>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import MainSection from "../components/MainSection";
import AboutUsSection from "@/components/AboutUsSection";
import WorkWithUsSection from "@/components/WorkWithUsSection";
import CurrentPositionsSection from "../components/CurrentPositionsSection.vue";
import store from "../store/index";

export default {
  name: "Home",
  components: {
    "main-section": MainSection,
    "about-us-section": AboutUsSection,
    "work-with-us-section": WorkWithUsSection,
    "current-positions-section": CurrentPositionsSection,
    Header,
    Footer
  },
  mounted() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  },
  beforeRouteEnter(to, from, next) {
    if (store.state.openings.length === 0) {
      store.dispatch("fetchOpenings").then(next);
    } else {
      next();
    }
  }
};
</script>
