<template>
  <button
    class="single_select"
    @click="onSelect"
    :class="{ selected_style: selected }"
  >
    <div>
      <img
        src="@/assets/images/tick.svg"
        alt=""
        :class="{ hidden: selected }"
        class="tick"
      />
      <img
        src="@/assets/images/tick_white.svg"
        alt=""
        :class="{ hidden: !selected }"
        class="tick"
      />
    </div>
  </button>
</template>

<script>
export default {
  name: "SingleSelectStripped",
  data() {
    return {
      selected: false
    };
  },
  props: {
    option: {
      type: String,
      default: ""
    }
  },
  methods: {
    onSelect() {
      this.selected = !this.selected;
      this.$emit("onClick", this.selected);
    }
  }
};
</script>

<style scoped>
.single_select {
  @apply rounded-full border text-sm border-customGray-superlight hover:bg-opacity-50 hover:bg-customLightBlue-dark font-titillium font-light flex w-10 h-10 flex-col justify-center items-center;
}
.selected_style {
  @apply bg-customLightBlue-dark text-white hover:bg-opacity-100;
}
</style>
