<template>
  <button
    id="apply_button"
    @click="onClickApply"
    :class="{ inactive: !active }"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "ApplyButton",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: "Apply"
    }
  },
  methods: {
    onClickApply: function() {
      if (this.active === true) {
        this.$emit("onClick");
      }
    }
  }
};
</script>

<style>
#apply_button {
  @apply font-titillium text-lg text-center w-full text-white py-3 bg-red active:bg-red-light hover:bg-opacity-50 focus:outline-none;
  @apply sm:text-sm sm:py-2;
  @apply md:text-base md:py-3;
  @apply lg:text-lg;
  -webkit-tap-highlight-color: transparent;
  max-width: 180px;
}
.inactive {
  @apply bg-customGray-light !important;
}
</style>
