<template>
  <div id="complete_section">
    <div class="main_heading">
      Congratulations!
    </div>
    <div class="sub_text">
      We have received your application. We will get back to you soon!
    </div>
    <div class="button_section">
      <apply-button :active="true" text="HOME" @onClick="onClickHome" />
    </div>
  </div>
</template>

<script>
import ApplyButton from "@/components/ApplyButton";
export default {
  name: "CompleteSection",
  components: {
    "apply-button": ApplyButton
  },
  methods: {
    onClickHome() {
      this.$router.replace("/");
    }
  },
  mounted() {
    window.onpopstate = () => {
      console.log("hello");
      this.$router.push("/"); // redirect to home
    };
  }
};
</script>

<style scoped>
#complete_section {
  @apply flex flex-col w-full px-0 py-12 items-start;
  @apply md:px-0 md:py-16;
  @apply lg:px-0 lg:py-5;
}
.main_heading {
  @apply font-titillium text-customLightBlue-dark text-3xl font-light;
  @apply sm:text-4xl;
  @apply md:text-5xl;
  @apply lg:text-6xl;
}
.sub_text {
  @apply text-xl font-light mt-10 leading-10;
  @apply sm:text-xl;
  @apply md:text-2xl;
  @apply lg:text-3xl;
}
.button_section {
  @apply mt-10 w-full max-w-xs;
}
</style>
