<template>
  <footer class="footer">
    <div class="flow_section">
      <div class="email">hello@kollcorp.com</div>
      <router-link to="/privacy_policy" class="link"
        >Privacy policy</router-link
      >
      <router-link to="/terms_of_use" class="link">Terms of use</router-link>
    </div>
    <div class="copy_rights">© Koll Corp Services. All rights reserved.</div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  @apply w-full h-auto px-5 py-10 bg-customLightBlue-dark flex flex-col font-titillium text-white font-light;
  @apply sm:px-5 sm:flex-row sm:justify-between;
  @apply md:px-10 md:py-20;
  @apply lg:px-20;
}
.email {
  @apply font-titillium text-white text-xl mb-3;
  @apply sm:text-base sm:mb-0;
  @apply md:text-lg lg:text-xl;
}
.link {
  @apply font-titillium text-white text-xl mb-3 block hover:underline;
  @apply sm:text-base sm:mb-0 sm:ml-3 md:ml-8 lg:ml-12;
  @apply md:text-lg lg:text-xl;
}
.link.router-link-exact-active {
  @apply underline;
}
.copy_rights {
  @apply mt-12 sm:text-xs sm:mt-1 md:text-sm lg:text-base;
}
.flow_section {
  @apply flex flex-col sm:flex-row;
}
</style>
