import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Apply from "@/views/Apply";
import ProfileForm from "@/components/ProfileForm";
import SkillsForm from "@/components/SkillsForm";
import VerifyForm from "@/components/VerifyForm";
import CompleteSection from "@/components/CompleteSection";
import PrivacyPolicy from "@/views/PrivacyPolicy";
import TermsOfUse from "@/views/TermsOfUse";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/privacy_policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy
  },
  {
    path: "/terms_of_use",
    name: "TermsOfUse",
    component: TermsOfUse
  },
  {
    path: "/apply/:id",
    component: Apply,
    children: [
      {
        path: "profile",
        component: ProfileForm
      },
      {
        path: "skills",
        component: SkillsForm
      },
      {
        path: "verify",
        component: VerifyForm
      },
      {
        path: "complete",
        component: CompleteSection
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
