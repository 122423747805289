<template>
  <div class="contaniner">
    <div class="header fixed z-50 w-screen">
      <Header />
    </div>
    <div id="apply">
      <div class="content">
        <div class="sub_heading">
          APPLY DIRECTLY
        </div>
        <div class="title">
          {{ opening.title }}
        </div>
        <div class="location">
          {{ opening.location }}
        </div>
        <div class="flow_section" :class="{ invisible: page === 'complete' }">
          <div class="section">
            <div
              class="step"
              :class="{
                step_active: page === 'profile',
                step_finished: page !== 'profile'
              }"
            >
              STEP 1
            </div>
            <div
              class="section_heading"
              :class="{
                step_active: page === 'profile',
                step_finished: page !== 'profile'
              }"
            >
              Profile
            </div>
          </div>
          <div class="section">
            <div
              class="step"
              :class="{
                step_active: page === 'skills',
                step_finished: page !== 'skills' && page !== 'profile'
              }"
            >
              STEP 2
            </div>
            <div
              class="section_heading"
              :class="{
                step_active: page === 'skills',
                step_finished: page !== 'skills' && page !== 'profile'
              }"
            >
              Skills
            </div>
          </div>
          <div class="section">
            <div
              class="step"
              :class="{
                step_active: page === 'verify',
                step_finished: page === 'complete'
              }"
            >
              STEP 3
            </div>
            <div
              class="section_heading"
              :class="{
                step_active: page === 'verify',
                step_finished: page === 'complete'
              }"
            >
              Verify
            </div>
          </div>
        </div>
      </div>
      <div class="form_section">
        <!-- if the form is not submitted yet -->
        <keep-alive :include="cacheEnabledComponentsList" v-if="keptAlive">
          <router-view></router-view>
        </keep-alive>
        <!-- otherwise don't keep anything alive -->
        <router-view v-else></router-view>
      </div>
    </div>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "Apply",
  data() {
    return {
      opening: this.$store.getters.getOpening(parseInt(this.$route.params.id)),
      cacheEnabledComponentsList: ["ProfileForm", "SkillsForm"]
    };
  },
  components: {
    Header,
    Footer
  },
  computed: {
    keptAlive() {
      if (
        this.$route.path ===
        "/apply/" + this.$route.params.id + "/complete"
      ) {
        return false;
      } else {
        return true;
      }
    },
    page() {
      switch (this.$route.path) {
        case "/apply/" + this.$route.params.id + "/profile":
          return "profile";
        case "/apply/" + this.$route.params.id + "/skills":
          return "skills";
        case "/apply/" + this.$route.params.id + "/verify":
          return "verify";
        case "/apply/" + this.$route.params.id + "/complete":
          return "complete";
        default:
          return "";
      }
    }
  },
  mounted() {
    // scroll back to top when mounted
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // trigger gtm page view
    this.$gtm.trackView("Apply Form", this.$route.path);
  },
  beforeRouteEnter(to, from, next) {
    if (store.state.openings.length === 0) {
      store.dispatch("fetchOpenings").then(() => {
        next();
      });
    } else {
      next();
    }
  }
};
</script>

<style scoped>
#apply {
  @apply w-full min-h-screen px-5 py-20 font-titillium pt-36;
  @apply sm:px-5 sm:pt-36;
  @apply md:px-10 md:pt-40;
  @apply lg:px-20 lg:pt-44;
}
.sub_heading {
  @apply text-sm font-extralight text-customLightBlue-dark;
  @apply sm:text-sm;
  @apply md:text-base;
}
.title {
  @apply text-2xl font-semibold leading-relaxed;
  @apply sm:text-2xl;
  @apply md:text-3xl;
  @apply lg:text-4xl lg:py-1;
}
.location {
  @apply text-base font-light text-customGray;
  @apply sm:text-base;
  @apply md:text-lg;
  @apply lg:text-xl;
}
.flow_section {
  @apply mt-8 flex flex-row justify-start w-3/4 font-titillium;
  @apply sm:justify-between;
  @apply md:mt-10;
  @apply lg:mt-12;
}
.step {
  @apply text-lg font-light text-customGray-superlight hidden;
  @apply sm:text-base sm:block;
  @apply md:text-lg;
  @apply lg:text-xl;
}
.section_heading {
  @apply text-lg font-semibold pt-0.5 text-customGray-superlight hidden;
  @apply sm:text-base sm:block;
  @apply md:text-lg;
  @apply lg:text-xl;
}
.step_active {
  @apply text-customLightBlue-dark block;
}
.step_finished {
  @apply text-red;
}
</style>
