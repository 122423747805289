<template>
  <div id="profile_form">
    <div class="back_button_section">
      <back-button />
    </div>
    <div class="inputs">
      <div class="flow_section">
        <div class="input_block">
          <input
            type="text"
            class="inputbox"
            id="firstname_input"
            placeholder="First name"
            v-model="first_name"
            :class="{ error_box: errorFirstName }"
          />
        </div>
        <span class="sm:px-5 md:px-10"></span>
        <div class="input_block">
          <input
            type="text"
            class="inputbox"
            id="lastname_input"
            placeholder="Last name"
            v-model="last_name"
            :class="{ error_box: errorLastName }"
          />
        </div>
      </div>
      <div class="flow_section">
        <div class="input_block">
          <input
            type="text"
            class="inputbox mob"
            id="mob_input"
            placeholder="Mobile"
            :value="mob"
            @input="onInputMob"
            :class="{ error_box: errorMob }"
          />
          <div class="plus">+91</div>
        </div>
        <span class="sm:px-5 md:px-10"></span>
        <div class="input_block">
          <input
            type="text"
            class="inputbox"
            id="email_input"
            placeholder="Email"
            v-model="email"
            :class="{ error_box: errorEmail }"
          />
        </div>
      </div>
      <div class="flow_section">
        <div class="input_block dropdown">
          <single-select-drop-down
            :options="qualificationOptions"
            placeholder="Qualification"
            @selected="onSelectQualification"
            :error="errorQualification"
          />
        </div>
        <span class="sm:px-5 md:px-10"></span>
        <div class="input_block dropdown">
          <single-select-drop-down
            :options="degreeOptions"
            placeholder="Undergrad major"
            @selected="onSelectDegree"
            :error="errorDegree"
          />
        </div>
      </div>
      <div class="flow_section">
        <div class="input_block">
          <input
            type="text"
            class="inputbox"
            id="academic_institution"
            placeholder="Academic institution"
            v-model="academic_institution"
            :class="{ error_box: errorAcademicInstitution }"
          />
        </div>
        <span class="sm:px-5 md:px-10"></span>
        <div class="input_block dropdown">
          <single-select-drop-down
            :options="yearsOptions"
            placeholder="Years of experience"
            @selected="onSelectYears"
            :error="errorYears"
          />
        </div>
      </div>
      <div class="" id="consent">
        <div class="">
          <custom-checkbox type="checkbox" v-model="consent_checked" />
        </div>
        <div :class="{ consent_error: errorConsent }" id="consent_heading">
          I agree to be contacted via phone, SMS, or email regarding my job
          application
        </div>
      </div>
      <div class="button_section">
        <apply-button
          :active="activateNext"
          text="NEXT"
          @onClick="onClickNext"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SingleSelectDropDown from "@/components/SingleSelectDropDown";
import CustomCheckbox from "@/components/CustomCheckbox";
import ApplyButton from "@/components/ApplyButton";
import BackButton from "./BackButton.vue";
export default {
  name: "ProfileForm",
  components: {
    "single-select-drop-down": SingleSelectDropDown,
    "custom-checkbox": CustomCheckbox,
    "apply-button": ApplyButton,
    "back-button": BackButton
  },
  data() {
    return {
      first_name: "",
      errorFirstName: false,
      last_name: "",
      errorLastName: false,
      mob: "",
      errorMob: false,
      email: "",
      errorEmail: false,
      degreeOptions: [
        "Computer Science or related",
        "Finance or related",
        "Marketing or related",
        "Other"
      ],
      errorDegree: false,
      selectedDegree: "",
      qualificationOptions: [
        "Ph.D or Post Doctoral",
        "Master's Degree",
        "Bachelor's Degree",
        "10+2 or equivalent",
        "10th Standard or below"
      ],
      errorQualification: false,
      selectedQualification: "",
      academic_institution: "",
      errorAcademicInstitution: false,
      yearsOptions: [
        "No experience",
        "1-3 years",
        "4-7 years",
        "More than 7 years"
      ],
      selectedYears: "",
      errorYears: false,
      consent_checked: false,
      errorConsent: false
    };
  },
  computed: {
    activateNext() {
      // next button activation
      if (
        this.validateFirstName() &&
        this.validateLastName() &&
        this.validateMob() &&
        this.validateEmail() &&
        this.validateQualification() &&
        this.validateDegree() &&
        this.validateAcademicInstitution() &&
        this.validateYears() &&
        this.validateConsent()
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    onClickNext() {
      // dispatch action to store applicant data
      this.$store
        .dispatch("setApplicantData", {
          first_name: this.first_name,
          last_name: this.last_name,
          mobile: this.mob,
          email: this.email,
          qualification: this.selectedQualification,
          degree: this.selectedDegree,
          work_experience: this.selectedYears,
          academic_institution: this.academic_institution,
          opening_id: parseInt(this.$route.params.id)
        })
        .then(() => {
          // navigate to skills
          this.$router.push("/apply/" + this.$route.params.id + "/skills");
        });
    },
    validateFirstName: function() {
      // special character detection format
      let specialCharacterFormat = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
      let digitFormat = /\d/;

      if (this.first_name === "") {
        this.errorFirstName = true;
        return false;
      } else if (
        specialCharacterFormat.test(this.first_name) ||
        digitFormat.test(this.first_name)
      ) {
        this.errorFirstName = true;
        return false;
      } else {
        this.errorFirstName = false;
        return true;
      }
    },
    validateLastName: function() {
      // special character detection format
      let specialCharacterFormat = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
      let digitFormat = /\d/;

      if (this.last_name === "") {
        this.errorLastName = true;
        return false;
      } else if (
        specialCharacterFormat.test(this.last_name) ||
        digitFormat.test(this.last_name)
      ) {
        this.errorLastName = true;
        return false;
      } else {
        this.errorLastName = false;
        return true;
      }
    },
    onInputMob: function(e) {
      //stop the input when input length reaches 10
      if (e.target.value.replace(/\D/g, "").length <= 10) {
        // strip characters
        var strippedMob = e.target.value.replace(/\D/g, "");
        // if the first value is 0, delete it
        if (strippedMob[0] === "0") {
          // slice the 0
          strippedMob = strippedMob.slice(1);
        }
        this.mob = strippedMob;
      }
      // the above equates to an empty string if the input is only characters, vue's reactivity will not update, so force update on every input
      this.$forceUpdate();
    },
    validateMob: function() {
      // if mobile is empty return false
      if (this.mob == "") {
        this.errorMob = true;
        return false;
      } else if (this.mob.length === 10) {
        this.errorMob = false;
        return true;
      }
    },
    validateEmail: function() {
      // email format in regex code
      var validEmailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (this.email === "") {
        this.errorEmail = true;
        return false;
      } else if (!validEmailFormat.test(this.email)) {
        this.errorEmail = true;
        return false;
      } else {
        this.errorEmail = false;
        return true;
      }
    },
    validateAcademicInstitution: function() {
      // special character detection format
      let specialCharacterFormat = /[!@#$%^&*()_+\-=[\]{};':"\\|<>/?]+/;
      let digitFormat = /\d/;

      if (this.academic_institution === "") {
        this.errorAcademicInstitution = true;
        return false;
      } else if (
        specialCharacterFormat.test(this.academic_institution) ||
        digitFormat.test(this.academic_institution)
      ) {
        this.errorAcademicInstitution = true;
        return false;
      } else {
        this.errorAcademicInstitution = false;
        return true;
      }
    },
    validateDegree: function() {
      // check whether degree is selcted
      if (this.selectedDegree != "") {
        this.errorDegree = false;
        return true;
      } else {
        this.errorDegree = true;
        return false;
      }
    },
    validateYears: function() {
      // check whether degree is selcted
      if (this.selectedYears != "") {
        this.errorYears = false;
        return true;
      } else {
        this.errorYears = true;
        return false;
      }
    },
    validateQualification: function() {
      // check whether qualification is selected
      if (this.selectedQualification != "") {
        this.errorQualification = false;
        return true;
      } else {
        this.errorQualification = true;
        return false;
      }
    },
    validateConsent: function() {
      // check if consent is checked
      if (this.consent_checked === false) {
        this.errorConsent = true;
        return false;
      } else {
        this.errorConsent = false;
        return true;
      }
    },
    onSelectDegree(value) {
      // set state
      this.selectedDegree = value;
    },
    onSelectYears(value) {
      // set state
      this.selectedYears = value;
    },
    onSelectQualification(value) {
      // set state
      this.selectedQualification = value;
    }
  },
  watch: {
    first_name() {
      // validate fist name input
      this.validateFirstName();
    },
    last_name() {
      // validate last name input
      this.validateLastName();
    },
    mob() {
      // validate mobile input
      this.validateMob();
    },
    email() {
      // validate email input
      this.validateEmail();
    },
    academic_institution() {
      // validate academic institution
      this.validateAcademicInstitution();
    }
  }
};
</script>

<style scoped>
#profile_form {
  @apply flex flex-col w-full px-0 py-12;
  @apply md:px-8 md:py-16;
  @apply lg:px-12 lg:py-20;
}
.inputbox {
  @apply focus:outline-none rounded-lg border border-customGray-light w-full px-4 py-2.5 font-titillium text-base placeholder-customGray-light font-normal focus:placeholder-opacity-0;
  @apply sm:px-3 sm:py-2.5 sm:text-sm;
  @apply md:px-5 md:py-3 md:text-sm;
  @apply lg:text-base;
}
.inputbox::placeholder {
  @apply font-extralight;
}
.error_box {
  @apply border-red;
}
.input_block {
  @apply mb-10 relative overflow-hidden max-w-xs w-full;
  @apply md:mb-10;
  @apply lg:mb-14;
}
.plus {
  @apply absolute top-0 font-titillium text-sm px-2 border-r border-customGray-light py-3.5 font-light text-red;
  @apply md:text-sm;
  @apply lg:text-base;
}
.mob {
  @apply pl-14 !important;
}
.dropdown {
  @apply overflow-visible !important;
}
.flow_section {
  @apply flex flex-col w-auto;
  @apply sm:flex-row sm:justify-start;
  @apply lg:w-3/4;
}
#consent {
  @apply flex flex-row items-start max-w-xs justify-start;
  @apply sm:max-w-none;
  @apply lg:w-3/4;
}
#consent_heading {
  @apply font-titillium text-customGray-light text-sm font-light ml-3;
}
.consent_error {
  @apply text-red !important;
}
.button_section {
  @apply mt-10;
  @apply lg:mt-14;
}
.back_button_section {
  @apply mb-8;
  @apply md:mb-10;
  @apply lg:mb-12;
}
</style>
