<template>
  <button id="back_button" @click="onClickBackButton">
    <img src="@/assets/images/arrow_back_red.svg" alt="" class="" />
    <span class="ml-2 text-red font-titillium font-medium">BACK</span>
  </button>
</template>

<script>
export default {
  name: "BackButton",
  methods: {
    onClickBackButton: function() {
      this.$router.back();
    }
  }
};
</script>

<style>
#back_button {
  @apply flex flex-row focus:outline-none active:opacity-50;
}
</style>
