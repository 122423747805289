<template>
  <header class="header">
    <img
      src="@/assets/images/logo.svg"
      alt=""
      class="logo"
      @click="onClickLogo"
    />
    <div class="email" @click="onClickEmail" id="email">
      hello@kollcorp.com
    </div>
  </header>
</template>

<script>
import { EventBus } from "@/EventBus";
export default {
  methods: {
    onClickLogo: function() {
      this.$router.replace("/").catch(() => {
        // current route is already the home
      });
    },
    onClickEmail: function() {
      // emit email clicked event
      EventBus.$emit("email_click");
    }
  }
};
</script>

<style scoped>
.header {
  @apply w-full h-auto flex flex-row py-5 px-5 items-center justify-between bg-white;
  @apply sm:px-5;
  @apply md:px-10;
  @apply lg:px-20;
}
.logo {
  @apply w-auto h-12 cursor-pointer;
  @apply md:h-12;
  @apply lg:h-14;
}
.email {
  @apply font-titillium text-customLightBlue-dark text-xl hidden cursor-pointer;
  @apply sm:block sm:text-base;
  @apply md:text-lg lg:text-xl;
}
</style>
