<template>
  <div id="about_us_section">
    <div class="main_content">
      We are disrupting the US $330 billion global contact center industry by
      creating the largest
      <span class="text-black font-normal inline-block"
        >Elastic Contact Center™</span
      >
      on earth that every business anywhere can afford.
    </div>

    <div class="incubated_section">
      <div class="heading">Incubated at</div>
      <img src="@/assets/images/nsrcel.svg" alt="" class="logo" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#about_us_section {
  @apply w-full min-h-screen bg-customGray-extralight flex flex-col justify-center items-start py-20 px-5;
  @apply md:px-10;
  @apply lg:px-20;
}
.main_content {
  @apply font-titillium text-2xl text-customGray font-extralight leading-relaxed;
  @apply sm:text-xl;
  @apply md:text-2xl md:leading-relaxed;
  @apply lg:w-4/5;
}
.incubated_section {
  @apply text-lg font-light mt-20;
  @apply sm:text-base;
  @apply md:text-lg;
  @apply lg:text-xl;
}
.logo {
  @apply h-16 w-auto;
  @apply md:h-14;
  @apply lg:h-16;
  @apply xl:h-20;
}
.heading {
  @apply mb-3 text-customGray-light;
}
</style>
