<template>
  <div class="" id="single_select_drop_down">
    <v-select
      class="dropdown"
      :options="options"
      v-model="optionSelected"
      :value="optionSelected"
      :placeholder="placeholder"
      :clearable="false"
      :searchable="false"
      :components="{ OpenIndicator }"
      :class="{ dropdown_error: error }"
    />
  </div>
</template>

<script>
import OpenIndicator from "@/components/OpenIndicator";

export default {
  name: "SingleSelectDropDown",
  props: {
    options: {
      type: Array
    },
    placeholder: {
      type: String
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      optionSelected: "",
      OpenIndicator: {
        render: () => <OpenIndicator />
      }
    };
  },
  watch: {
    optionSelected: function(newValue) {
      this.$emit("selected", newValue);
    }
  }
};
</script>

<style>
#single_select_drop_down {
  @apply w-full max-w-xs;
}
.dropdown .vs__dropdown-toggle {
  @apply border border-customGray-light rounded-md !important;
}
.dropdown_error .vs__dropdown-toggle {
  @apply border border-red rounded-md !important;
}
.dropdown .vs__search {
  @apply py-1 pl-4 pr-0 sm:pl-2 md:pl-4;
  @apply md:py-1.5;
}
.dropdown .vs__search:focus {
  @apply py-0 pl-4 pr-0 sm:pl-2 md:pl-4;
}
.dropdown .vs__selected {
  @apply font-titillium pl-4 sm:pl-2 md:pl-4;
}
.dropdown .vs__actions {
  @apply pr-4 sm:pr-2 md:pr-4;
}
.dropdown .vs--single.vs--open .vs__selected {
  @apply hidden !important;
}
.dropdown .vs__dropdown-option--highlight {
  @apply bg-customLightBlue-dark;
}
.dropdown .vs__dropdown-menu {
  max-height: 150px;
}
.dropdown .vs__dropdown-option {
  @apply font-titillium;
}
.dropdown .vs__search::placeholder {
  @apply font-titillium text-base text-customGray-light font-extralight;
}
</style>
