<template>
  <div id="work_with_us_section">
    <div class="left_section">
      <div class="main_heading">
        Work with us
      </div>
      <div class="sub_heading">
        Koll Corp offers a rich startup experience.
      </div>
      <div class="content">
        We follow the principle of effectuation:
        <span class="font-semibold"
          >Set short-term goals and focus on executing well.
        </span>
        <br /><span class="h-2 hidden sm:block"></span>Our team is a mix of
        youth and experience, but everyone shares the hunger to build great
        products and succeed. All our team members come with a
        founder-mentality, and we make sure that everyone gets cross-domain
        exposure. <br /><span class="h-2 hidden sm:block"></span
        ><span class="text-red font-semibold"
          >Koll Corp is a great place to work if you are super-motivated and
          aspire to start up but don’t have the resources or cannot afford the
          risks involved.</span
        >
      </div>
    </div>
    <div class="right_section">
      <div class="main_heading">
        We do not negotiate salaries
      </div>
      <div class="sub_heading">
        We offer the best that we can and count on growing together.
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#work_with_us_section {
  @apply w-full min-h-screen flex flex-col px-5 py-20 justify-center items-center;
  @apply sm:flex-row;
  @apply md:px-10;
  @apply lg:px-20;
}
.left_section {
  @apply w-full font-titillium;
  @apply sm:mr-5;
  @apply md:mr-10;
  @apply lg:mr-20;
}
.left_section .main_heading {
  @apply text-customLightBlue-dark text-5xl font-light;
  @apply sm:text-4xl;
  @apply md:text-5xl;
  @apply lg:text-6xl;
}
.left_section .sub_heading {
  @apply text-2xl font-semibold mt-8 leading-relaxed;
  @apply sm:text-lg;
  @apply md:text-xl;
  @apply lg:text-2xl;
}
.left_section .content {
  @apply text-base font-light mt-5 leading-8 !important;
  @apply sm:text-xs;
  @apply md:text-sm;
  @apply lg:text-base;
}
.right_section {
  @apply w-full font-titillium mt-20;
  @apply sm:mt-0;
}
.right_section .main_heading {
  @apply text-customLightBlue-dark text-5xl leading-tight font-light;
  @apply sm:text-5xl;
  @apply md:text-6xl;
  @apply lg:text-7xl;
}
.right_section .sub_heading {
  @apply text-3xl font-light mt-10 leading-10 !important;
  @apply sm:text-xl;
  @apply md:text-2xl;
  @apply lg:text-3xl;
}
</style>
