<template>
  <div class="" id="current_positions_section">
    <div class="main_heading">
      Current positions
    </div>
    <div
      class="flow_section"
      v-for="index in number_of_flow_section"
      :key="index"
    >
      <div class="left_section">
        <div class="title">
          {{
            left_section_item(index) !== null
              ? left_section_item(index).title
              : ""
          }}
        </div>
        <div class="location">
          {{
            left_section_item(index) !== null
              ? left_section_item(index).location
              : ""
          }}
        </div>
        <div class="description" v-if="left_section_item(index) !== null">
          <div
            class="item"
            v-for="(item, i) in left_section_item(index).description"
            :key="i"
          >
            <img src="@/assets/images/arrow_right.svg" alt="" class="arrow" />
            {{ item }}
          </div>
        </div>
        <div class="button_section" v-if="left_section_item(index) !== null">
          <apply-button
            :active="true"
            @onClick="onClickApply(left_section_item(index))"
          />
        </div>
      </div>
      <div class="right_section">
        <div class="title">
          {{
            right_section_item(index) !== null
              ? right_section_item(index).title
              : ""
          }}
        </div>
        <div class="location">
          {{
            right_section_item(index) !== null
              ? right_section_item(index).location
              : ""
          }}
        </div>
        <div class="description" v-if="right_section_item(index) !== null">
          <div
            class="item"
            v-for="(item, i) in right_section_item(index).description"
            :key="i"
          >
            <img src="@/assets/images/arrow_right.svg" alt="" class="arrow" />
            {{ item }}
          </div>
        </div>
        <div class="button_section" v-if="right_section_item(index) !== null">
          <apply-button
            :active="true"
            @onClick="onClickApply(right_section_item(index))"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApplyButton from "@/components/ApplyButton";
import store from "@/store";
export default {
  name: "CurrentPositionsSection",
  components: {
    "apply-button": ApplyButton
  },
  data() {
    return {
      // get all the jobs
    };
  },
  computed: {
    number_of_flow_section() {
      // return how many flow sections needed. ie, number of jobs divided by 2
      return Math.ceil(this.jobs.length / 2);
    },
    jobs() {
      return this.$store.state.openings;
    }
  },
  methods: {
    left_section_item(row_index) {
      // return first element of a job row based on current row index
      let index = (row_index - 1) * 2;
      // check if the index is valid
      if (this.jobs[index] !== undefined) {
        return this.jobs[index];
      } else {
        return null;
      }
    },
    right_section_item(row_index) {
      // get index of second element in a row based on the current row index
      let index = (row_index - 1) * 2 + 1;
      // check if the index is valid
      if (this.jobs[index] !== undefined) {
        return this.jobs[index];
      } else {
        return null;
      }
    },
    onClickApply(item) {
      this.$router.push("/apply/" + item.id + "/profile");
    }
  },
  beforeRouteEnter(to, from, next) {
    if (store.state.openings.length === 0) {
      store.dispatch("fetchOpenings").then(next);
    } else {
      console.log("hello");
      next();
    }
  }
};
</script>

<style scoped>
#current_positions_section {
  @apply w-full min-h-screen flex flex-col justify-center bg-customGray-extralight px-5 py-20;
  @apply sm:px-5;
  @apply md:px-10;
  @apply lg:px-20;
}
.main_heading {
  @apply font-titillium text-customLightBlue-dark text-5xl font-light;
  @apply sm:text-4xl;
  @apply md:text-5xl;
  @apply lg:text-6xl;
}
.flow_section {
  @apply font-titillium flex flex-col;
  @apply sm:flex-row sm:justify-between;
}
.left_section {
  @apply py-10 w-full;
  @apply sm:mr-5 sm:py-5;
  @apply md:mr-10 md:py-10;
  @apply lg:mr-20 lg:py-20;
}
.right_section {
  @apply py-10 w-full;
  @apply sm:py-5;
  @apply md:py-10;
  @apply lg:py-20;
}
.title {
  @apply text-2xl font-semibold leading-relaxed;
  @apply sm:text-lg;
  @apply md:text-xl;
  @apply lg:text-2xl;
}
.location {
  @apply text-xl font-light text-customGray;
  @apply sm:text-base;
  @apply md:text-lg;
  @apply lg:text-xl;
}
.description {
  @apply mt-5;
}
.item {
  @apply flex flex-row items-start font-titillium text-lg py-2;
  @apply lg:py-1.5;
}
.arrow {
  @apply mr-3 w-5 h-5 mt-1;
}
.button_section {
  @apply mt-10;
}
</style>
