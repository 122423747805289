<template>
  <div>
    <!-- if the form is not submitted yet -->
    <keep-alive :include="cacheEnabledComponentsList" v-if="keptAlive">
      <router-view></router-view>
    </keep-alive>
    <!-- otherwise don't keep anything alive -->
    <router-view v-else></router-view>

    <div id="snackbar" :class="{ show: show_snackbar }">Copied !</div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
export default {
  name: "App",
  data() {
    return {
      show_snackbar: false,
      keptAlive: false,
      cacheEnabledComponentsList: ["ProfileForm", "SkillsForm"]
    };
  },
  mounted() {
    EventBus.$on("email_click", () => {
      // copy link to clipboar
      this.$copyText("hello@kollcorp.com")
        .then(() => {
          // show snackbar
          // only if snack_bar is hidden
          if (this.show_snackbar !== true) {
            this.show_snackbar = true;
            setTimeout(() => {
              // hide snackbar
              this.show_snackbar = false;
            }, 3000);
          }
        })
        .catch(err => {
          console.log(err);
        });
    });
  }
};
</script>

<style scoped>
.header {
  @apply w-screen h-auto;
}
/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 200px; /* Set a default minimum width */
  margin-left: -100px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
  @apply bg-customGray text-white;
}
.show {
  visibility: visible !important; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
</style>
