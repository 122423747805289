import Vue from "vue";
import Vuex from "vuex";
import Services from "../services";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    openings: [],
    applicant: {}
  },
  mutations: {
    setOpenings(state, openings) {
      state.openings = openings;
    },
    setApplicant(state, applicant) {
      state.applicant = applicant;
    }
  },
  getters: {
    getOpening: state => id => {
      // get opening by id
      return state.openings.find(item => item.id === id);
    },
    getApplicant: state => {
      return state.applicant;
    }
  },
  actions: {
    fetchOpenings(context) {
      return new Promise((resolve, reject) => {
        // fetch openings from server
        Services.fetchOpenings()
          .then(data => {
            context.commit("setOpenings", data.openings);
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    setApplicantData(context, data) {
      console.log("setting applicant data");
      return new Promise(resolve => {
        // set store state
        context.commit("setApplicant", {
          ...context.state.applicant,
          ...data
        });
        // resolve
        resolve();
      });
    }
  },
  modules: {}
});
